<template>
  <v-container fluid>
    
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <ButtonAdicionar
            :temPermissao="true"
            :to="`/cliente/${$route.params.cliente}/contrato/adicionar`"
          />
        </v-col>
      </v-row>
    </v-card-actions>
    <v-row class="mt-4">
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="totalRegistros"
          :headers="cabecalho"
          :items="contratos"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
          @update:options="carregarContratos"
        >
          <template v-slot:[`item.actions`]="{ item }" class="text-right">
            <table-btn-veiculo
              :temPermissao="
                $store.getters['usuario/temPermissao']('mostrar.veiculos')
              "
              :href="
                `/cliente/${$route.params.cliente}/contrato/${item.id}/veiculos`
              "
            />
            <table-btn-editar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.contratos')
              "
              :href="
                `/cliente/${$route.params.cliente}/contrato/${item.id}/editar`
              "
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import contratos from "@/api/contratos";
import formatarData from "@/utils/formatar-data-input";

export default {
  components: {
    ButtonAdicionar: () => import("@/components/button/ButtonAdicionar"),
  },

  data() {
    return {
     
      totalRegistros: 0,
      cabecalho: [
        { text: "#", value: "id" },
        { text: "Área", value: "area.nome" },
        { text: "Início", value: "inicio" },
        { text: "Término", value: "termino" },
        { text: "Vagas", value: "vagas" },
        { text: "Valor", value: "valor" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      contratos: [],
      paginacao: {},
      tab: null,
      clienteID: null,
    };
  },

  methods: {
    async carregarContratos() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        this.clienteID = this.$route.params.cliente;
        const resposta = await contratos.buscarContratosDoCliente(
          this.clienteID
        );

        this.contratos = resposta.data.data;
        this.contratos.forEach((item) => {
          if (item.ativo == 1) {
            item.status = "Ativo";
          } else {
            item.status = "Inativo";
          }
          item.inicio = formatarData.ptBr(item.inicio);
          item.termino = formatarData.ptBr(item.termino);
        });

        console.log(resposta.data);

        this.totalRegistros = resposta.data.meta.total;
      } catch (e) {
        this.$snackbar.mostrar({
          cor: "error",
          mensagem: this.$erroApi._(e),
        });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>

<style scoped>
.tables {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
